.header{
    display: flex;
    justify-content: space-between;
}

.logo{
    width: 6rem;
    height: 6rem;
    border-radius: 50%;
}

.header-menu{
    list-style: none;
    display: flex;
    gap: 2rem;
    color: white;
}

.header-menu li{
    cursor: pointer;
}

.header-menu>li:hover{
   color: var(--orange);
}

@media  screen and (max-width:768px){
    .header>:nth-child(2){
        position: fixed;
        right: 2rem;
        z-index: 99;
    }
    .header-menu{
        flex-direction: column;
        background-color: var(--appColor);
        padding: 2rem;
    }
}
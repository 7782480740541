.footer-container{
    position: relative;
}

.footer-container>hr{
    border: 1px solid var(lightgray);
}

.footer{
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    gap: 4rem;
    align-content: center;
    align-items: center;
    justify-content: center;
    height: 20rem;
}

.social-links{
    display: flex;
    gap: 4rem;
}

.social-links>a>img{
    width: 2rem;
    height: 2rem;
    cursor: pointer;
}

.logo-f>img{
    width: 6rem;
    height: 6rem;
    border-radius: 50%;
}

@media  screen and (max-width:768px) {
    .footer{
        margin-bottom: 3rem;
        margin-top: 0;
    }
}